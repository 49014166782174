import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { filter, tap } from 'rxjs';
import { isLoggedIn } from './state/auth/auth.selectors';
import Logger from './logger.service';

const log = Logger('guard:auth');

export const AuthGuard: CanActivateFn = () => {
  const store = inject(Store);
  const router = inject(Router);

  return store.pipe(
    select(isLoggedIn),
    // Check if loggedIn is boolean
    filter((loggedIn) => typeof loggedIn === 'boolean'),
    tap((loggedIn) => {
      log('Auth guard loggedIn', loggedIn);
      if (loggedIn === false) {
        let redirectUrl = location.href.replace(location.origin, '');
        if (redirectUrl.startsWith('/login')) {
          redirectUrl = '/'
        }
        router.navigate(['/login'], { queryParams: { redirect: redirectUrl } });
      }
    })
  );
};
